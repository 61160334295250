//
// The old styling for content carousels : still used by CarouselRow
// These carousels don't use swipers inbuilt Navigation
// instead nav buttons and rtl switch is handled in CarouselRow component
//
.carousel_new {
  .nav_button {
    cursor: pointer;
    height: 74px;
    width: 74px;
    position: absolute;
    top: 45%;
    @include translatecentery();
    background: $color_purple;
    background-size: 35% auto;
    background-position: center;
    background-repeat: no-repeat;
    &.nav_left {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      left: 20px;
      @include backgroundimage('icons/arrow_left.svg');
      @include transformorigin(0% 50%);
      @include carouselArrowsInActive(60deg);
    }
    &.nav_right {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      right: 20px;
      @include backgroundimage('icons/arrow_right.svg');
      @include transformorigin(100% 50%);
      @include carouselArrowsInActive(90deg);
    }
    &.disabled {
      display: none;
    }
  }
}

.carousel_new {
  .nav_button:hover {
    background-size: 45% auto;
  }
  &:hover .nav_button {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .nav_button.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
  }
}
