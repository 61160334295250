// https://sass-lang.com/documentation/modules/math
@use "sass:math";

@import 'base';
@import 'typography';

html {
  scroll-behavior: smooth;
}

//.placeholder-loading {
//  position: absolute;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: 100%;
//  height: 100%;
//  .icon {
//    width: 40px;
//    height: 40px;
//    text-align: center;
//  }
//}

//.loading {
//  display: flex;
//  justify-content: center;
//  .icon_container {
//    width: 100px;
//    height: 100px;
//  }
//}

/* overrides the site style to adjust for slick slider
.carousel_widget.three_onscreen {
  .carousel_item {
    width: 30.33%;
    width: -webkit-calc(33.33% - 1.6%);
    width: calc(33.33% - 1.6%);
    margin-right: 1.6%;
  }
} */

.carousel-fade {
  opacity: 0.01;
}
.carousel-fade-enter {
  opacity: 0.01;
}
.carousel-fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-out;
}

/* LAZY IMAGES Animations */
.animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@import 'carousel_nav';
@import 'carousel_nav_swiper';
